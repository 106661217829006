import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DeleteFilledIcon from '@material-ui/icons/Delete';

import ProductTableCell from 'components/PdfCatalogs/ProductTableCell';
import PriceInput from 'components/PdfCatalogs/PriceInput';
import TextInput from 'components/PdfCatalogs/TextInput';
import IOSSwitch from 'components/shared/IOSSwitch';

const useStyles = makeStyles(({ palette, spacing, breakpoints }) => ({
  tableRow: {
    height: 64,
    borderBottom: palette.border.lightGreyThinLight,
    // borderLeft: palette.border.lightGreyBold,
    // borderRight: palette.border.lightGreyBold,
    '&:first-child': {
      borderTop: palette.border.grey,
    },
  },
  tableProductDetailsRow: {
    height: 64,
    borderBottom: palette.border.grey,
    // borderLeft: palette.border.lightGreyBold,
    // borderRight: palette.border.lightGreyBold,
  },
  tableRowError: {
    // backgroundColor: palette.error.background,
    // borderLeft: palette.border.errorBold,
    // borderRight: palette.border.errorBold,
  },
  tableCell: {
    padding: spacing(1, 1.5),
    maxWidth: 150,
    borderBottom: 'none',
    [breakpoints.up('lg')]: {
      padding: spacing(0, 1),
    },
    '&:first-child': {
      // paddingLeft: spacing(3.5),
    },
    '&:last-child': {
      // paddingRight: spacing(3.5),
    },
  },
  tableActionButton: {
    minWidth: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    padding: spacing(2),
    margin: `0px ${spacing(0.5)}px`,
    backgroundColor: palette.background.button,
    border: palette.border.grey,
  },
  tableActionButtonIcon: {
    width: 20,
    height: 20,
    color: palette.secondary.darkGray,
  },
  tableActionButtonIconClicked: {
    color: palette.primary.main,
  },
  formControl: {
    margin: spacing(0),
    fontSize: '14px',
  },
  tableRowOdd: {
    backgroundColor: palette.background.mutedLight,
  },
  tableRowToDelete: {
    backgroundColor: palette.error.background,
  },
  leftMostColumn: {
    paddingLeft: spacing(1.5),
  },
  wrapText: {
    textWrap: 'nowrap',
  },
  pricePackageSeparator: {
    margin: spacing(0.5),
  },
}));

function DetailsTableRow({
  rowData,
  isEditMode,
  productChanges,
  isCatalogPricePerBaseUnit,
  handleCellChange,
  handleDeleteRow,
}) {
  const classes = useStyles();

  const pdfCatalogProductId = rowData?._id;
  const productCopy = rowData?.productCopy;
  const noProductFound = !productCopy;

  const newlySelectedPackaging =
    productChanges?.packaging || rowData?.packaging;
  const selectedVariant =
    productCopy?.variants?.find(
      variant => variant?.packaging === newlySelectedPackaging
    ) || productCopy?.variants?.[0];

  const variantExists = !!selectedVariant;
  const variantPrice = selectedVariant?.price;
  const unitsInPackaging = selectedVariant?.unitsInPackaging;

  const vendorSpecificIdValue =
    productChanges?.vendorSpecificId || rowData.vendorSpecificId;

  const variantBaseUnit = selectedVariant?.baseUnit;

  const packagingOptions = productCopy?.variants?.map(
    variant => variant?.packaging
  );

  const variantPackaging = selectedVariant?.packaging;

  let isBaseUnitPrice = rowData?.isBaseUnitPrice;
  if (typeof productChanges?.isBaseUnitPrice === 'boolean') {
    isBaseUnitPrice = productChanges.isBaseUnitPrice;
  } else if (
    isCatalogPricePerBaseUnit &&
    typeof isBaseUnitPrice !== 'boolean'
  ) {
    isBaseUnitPrice = isCatalogPricePerBaseUnit;
  }

  return (
    <Fragment key={pdfCatalogProductId}>
      <TableRow
        className={classNames(
          classes.tableRow,
          productChanges[pdfCatalogProductId]?.toDelete &&
            classes.tableRowToDelete,
          classes.tableRowOdd,
          !variantExists && classes.tableRowError
        )}
      >
        <TableCell
          className={classNames(classes.tableCell, classes.leftMostColumn)}
        >
          <Typography variant="body2">PDF</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <TextInput
            name="vendorSpecificId"
            staticValue={
              vendorSpecificIdValue ? `${vendorSpecificIdValue} ✨` : ''
            }
            value={vendorSpecificIdValue}
            isEditMode={isEditMode}
            setFieldValue={e =>
              handleCellChange(
                pdfCatalogProductId,
                'vendorSpecificId',
                e.target?.value
              )
            }
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Typography variant="body2">{rowData.name}</Typography>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
            className={classes.wrapText}
          >
            <PriceInput
              price={productChanges?.price || rowData.price || ''}
              disabled={!isBaseUnitPrice}
              isEditMode={isEditMode && isBaseUnitPrice}
              handleChangePrice={e => {
                const { target } = e;
                handleCellChange(pdfCatalogProductId, 'price', target?.value);
              }}
            />
            {isBaseUnitPrice && variantBaseUnit && (
              <Typography variant="body2">&nbsp;/ {variantBaseUnit}</Typography>
            )}
          </Grid>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
          >
            <PriceInput
              price={
                productChanges?.price || rowData.price || variantPrice || 0
              }
              disabled={isBaseUnitPrice}
              hidden={packagingOptions?.length && isBaseUnitPrice}
              isEditMode={isEditMode && !isBaseUnitPrice}
              handleChangePrice={e =>
                handleCellChange(pdfCatalogProductId, 'price', e?.target?.value)
              }
            />
            {!isBaseUnitPrice && variantPackaging && (
              <Typography
                className={classes.pricePackageSeparator}
                variant="body2"
              >
                /
              </Typography>
            )}
            {packagingOptions?.length > 1 ? (
              <FormControl className={classes.formControl}>
                <Select
                  value={variantPackaging}
                  onChange={event =>
                    handleCellChange(
                      pdfCatalogProductId,
                      'packaging',
                      event.target.value
                    )
                  }
                  disabled={noProductFound || !isEditMode}
                >
                  {packagingOptions?.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              <Typography variant="body2">{variantPackaging}</Typography>
            )}
          </Grid>
        </TableCell>
        <TableCell className={classes.tableCell}>
          <IOSSwitch
            checked={isBaseUnitPrice || false}
            color="primary"
            disabled={!isEditMode}
            inputProps={{ 'aria-label': 'Per base unit' }}
            onChange={e =>
              handleCellChange(
                pdfCatalogProductId,
                'isBaseUnitPrice',
                e.target?.checked
              )
            }
          />
        </TableCell>
        <TableCell className={classes.tableCell}>
          <Grid container wrap="nowrap">
            <Button
              variant="contained"
              disabled={!isEditMode}
              classes={{
                root: classes.tableActionButton,
              }}
              disableElevation
              onClick={handleDeleteRow}
            >
              {productChanges?.toDelete ? (
                <DeleteFilledIcon
                  className={classNames(
                    classes.tableActionButtonIcon,
                    classes.tableActionButtonIconClicked
                  )}
                />
              ) : (
                <DeleteIcon
                  className={classNames(classes.tableActionButtonIcon)}
                />
              )}
            </Button>
          </Grid>
        </TableCell>
      </TableRow>

      <TableRow
        className={classNames(
          classes.tableProductDetailsRow,
          productChanges[pdfCatalogProductId]?.toDelete &&
            classes.tableRowToDelete,
          // !isOddRow && classes.tableRowOdd,
          !variantExists && classes.tableRowError
        )}
      >
        <ProductTableCell
          value="Chefslist"
          extraClassNames={[classes.leftMostColumn]}
          // emptyValue="🛑 Not found"
          // isEmpty={!productCopy}
        />

        <ProductTableCell
          value={`${productCopy?.vendorSpecificId} ✅`}
          emptyValue="N/A 🛑"
          isEmpty={!productCopy}
        />

        <ProductTableCell
          value={productCopy?.name}
          emptyValue="-"
          isEmpty={!productCopy?.name}
        />
        <ProductTableCell
          value={Number.parseFloat(
            variantPrice / (unitsInPackaging || 1)
          ).toFixed(2)}
          isPrice
          emptyValue="-"
          isEmpty={!variantPrice}
        />

        <ProductTableCell
          value={variantPrice}
          isPrice
          emptyValue="-"
          isEmpty={!variantPrice}
        />

        <TableCell className={classes.tableCell} />
        <TableCell className={classes.tableCell} />
      </TableRow>
    </Fragment>
  );
}

DetailsTableRow.defaultProps = {
  isCatalogPricePerBaseUnit: null,
};

DetailsTableRow.propTypes = {
  rowData: PropTypes.object.isRequired,
  isEditMode: PropTypes.bool.isRequired,
  productChanges: PropTypes.object.isRequired,
  isCatalogPricePerBaseUnit: PropTypes.bool,
  handleCellChange: PropTypes.func.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

export default React.memo(
  DetailsTableRow,
  (prevProps, nextProps) =>
    isEqual(prevProps.rowData, nextProps.rowData) &&
    prevProps.isEditMode === nextProps.isEditMode &&
    isEqual(prevProps.productChanges, nextProps.productChanges) &&
    prevProps.isCatalogPricePerBaseUnit === nextProps.isCatalogPricePerBaseUnit
);
