import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import noop from 'lodash/noop';
import debounce from 'lodash/debounce';
import classNames from 'classnames';
import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import Skeleton from '@material-ui/lab/Skeleton';

import BackIcon from '@material-ui/icons/ArrowBack';
import { ReactComponent as MagnifyingGlass } from 'assets/magnifyingGlass.svg';

import { ROWS_PER_PAGE_OPTIONS } from 'helpers/constants';
import TableSkeletons from 'components/shared/TableSkeletons';
import EmptyTableData from 'components/shared/EmptyTableData';

const useStyles = makeStyles(({ spacing, breakpoints, palette }) => ({
  tableContainerBase: {
    // width: "90vw",
    maxWidth: 'unset',
    minHeight: '30vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [breakpoints.up('xl')]: {
      maxWidth: 1720,
    },
  },
  tableContainer: {
    borderRadius: spacing(1),
    border: palette.border.lightGreyThinDark,
  },
  tableContainerV2: {
    border: 'none !important',
    backgroundColor: 'transparent',
  },
  fixedLayout: {
    tableLayout: 'fixed',
  },
  tableHeaderContainer: {
    padding: spacing(2.5, 3.5, 1),
    [breakpoints.only('lg')]: {
      padding: spacing(2.5, 5, 1, 2.5),
    },
  },
  tableTitleContainer: {
    marginBottom: spacing(2),
    width: '100%',
    [breakpoints.up('xl')]: {
      maxWidth: 1720,
    },
  },
  tableTitle: {
    textAlign: 'left',
    width: '100%',
    fontWeight: 700,
  },
  tableHead: {
    borderBottom: palette.border.lightGreyThinDark,
  },
  backButton: {
    width: 40,
    height: 40,
  },
  tableSearchBarContainer: {
    // borderBottom: palette.border.lightGrey,
    padding: spacing(1, 0),
  },
  tableSearchBarContainerV2: {
    padding: 0,
    paddingBottom: spacing(0.25),
  },
  tableSearchBarRoot: {
    height: 45,
    fontSize: 14,
    borderRadius: 8,
    [breakpoints.up('lg')]: {
      fontSize: 16,
    },
    border: palette.border.lightGrey,
    '&$cssFocused $notchedOutline': {
      borderColor: `${palette.primary.main} !important`,
    },
  },
  tableSearchBarRootV2: {
    width: 350,
    height: 40,
    fontSize: 14,
    border: palette.border.grey,
    background: palette.background.paper,
    boxSizing: 'content-box',
    borderRadius: 32,
    '&$cssFocused $notchedOutline': {
      borderColor: `${palette.primary.main} !important`,
    },
  },
  tableSearchBarInputV2: {
    width: 300,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  outlinedRoot: {
    paddingTop: spacing(0.25),
  },
  cssFocused: {},
  notchedOutline: {
    borderWidth: '1px !important',
    borderColor: `${palette.secondary.lightGray} !important`,
    '&:hover': {
      border: `1px solid ${palette.primary.main}`,
    },
  },
  tableSearchBarOutlined: {
    border: palette.border.lightGrey,
  },
  tableHeaderRowCell: {
    color: palette.text.secondary,
    borderBottom: 'none',
    padding: spacing(2.5, 1.5, 1.5),
    whiteSpace: 'nowrap',
  },
  tableHeaderRowCellLeftAction: {
    width: 40,
  },
  tableHeaderCellLeftActionPadding: {
    paddingLeft: spacing(4),
  },
  tablePaginationRoot: {
    width: '100%',
  },
  tablePagination: {
    height: 68,
  },
  tablePaginationSelectRoot: {
    border: palette.border.grey,
    borderRadius: spacing(3),
    '&:focus': {
      borderRadius: spacing(3),
    },
  },
  tablePaginationSelect: {
    borderRadius: spacing(3),
    '&:focus': {
      borderRadius: spacing(3),
    },
  },
  tablePaginationActions: {
    marginRight: spacing(1),
    [breakpoints.up('lg')]: {
      marginRight: spacing(2.5),
    },
  },
  tablePaginationButton: {
    border: palette.border.grey,
    padding: '3px',
    '&:first-child': {
      marginRight: spacing(1),
    },
  },
  paginationSkeletonContainer: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: 68,
    paddingRight: spacing(1),
  },
  rowsPerPageSkeleton: {
    width: 180,
    height: 20,
    marginRight: spacing(12),
  },
  pageResultsSkeleton: {
    width: 130,
    height: 20,
    marginRight: spacing(2.5),
  },
  paginationActionsSkeleton: {
    width: 36,
    height: 36,
    margin: spacing(0, 1),
  },
  footerRoot: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  tableBody: {
    position: 'relative',
  },
  tableV2: {
    backgroundColor: palette.background.paper,
    marginTop: spacing(2),
    borderRadius: 8,
  },
  tableBodyV2: {
    position: 'relative',
    // '& tr[data-attr="table-body-row"]:nth-child(odd)': {
    //   backgroundColor: palette.background.mutedLight,
    // },
    '& tr[class*="tableBodyRowV2"]:nth-child(odd)': {
      backgroundColor: palette.background.mutedLight,
    },
    // border: `1px solid ${palette.background.mutedLight}`,
  },
  tableLayoutLoader: {
    borderRadius: 8,
  },
}));

export default function TableLayout(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const currentPath = location.pathname;
  const queries = queryString.parse(location.search);

  const [searchBarInput, setSearchBarInput] = useState('');

  const {
    withTableLayoutV2Styles,
    title,
    subtitleNode,
    wrapTitle,
    titleDirection,
    headerActionsBeforeSearchBar,
    withDetailPanel,
    withSearchBar,
    withActions,
    handleSearchBar,
    handleShowSearchResult,
    columns,
    children,
    page,
    rowsPerPage,
    totalResults,
    handleChangePage,
    handleChangeRowsPerPage,
    headerActions,
    sortBy,
    sortOrder,
    handleSortRows,
    handleClickAway,
    isEditMode,
    hasCheckBox,
    numSelected,
    onSelectAllClick,
    itemsOnPage,
    emptyTableData,
    emptyTableDataMessage,
    headerAdditionalActions,
    backButtonAction,
    tableIsLoading,
    searchPlaceholder,
    skeletonType,
    urlSearchParameter,
    fixedLayout,
    footerAdditionalActions,
    noHeaderPaddings,
    noTableHeadUnderline,
    tableContainerCustomClass,
    tableSearchBarContainerCustomClass,
    customRowsPerPageOptions,
  } = props;

  const updateQuery = () => {
    if (searchBarInput.trim().length > 2) {
      handleSearchBar(searchBarInput);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(debounce(updateQuery, 500), [
    searchBarInput,
  ]);

  useEffect(() => {
    delayedQuery();

    return delayedQuery.cancel;
  }, [searchBarInput, delayedQuery]);

  useEffect(() => {
    if (urlSearchParameter) {
      setSearchBarInput(urlSearchParameter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearchBarInput = e => {
    const { value } = e.target;

    if (value.length === 0 && searchBarInput.length !== 0) {
      const updParams = { ...queries };
      delete updParams.search;
      history.replace({
        pathname: currentPath,
        search: queryString.stringify(updParams),
      });
      handleShowSearchResult(false);
    }
    setSearchBarInput(value);
  };

  const sortDirection = sortOrder === 1 ? 'asc' : 'desc';

  return (
    <>
      <Grid
        container
        alignItems="center"
        wrap={wrapTitle || 'nowrap'}
        direction={titleDirection || 'column'}
        className={classes.tableTitleContainer}
      >
        {title && (
          <Typography className={classes.tableTitle} variant="h4">
            {backButtonAction && (
              <IconButton
                className={classes.backButton}
                size="small"
                onClick={backButtonAction}
              >
                <BackIcon color="primary" />
              </IconButton>
            )}
            {title}
          </Typography>
        )}
        {subtitleNode && subtitleNode}
      </Grid>

      <TableContainer
        component={Paper}
        elevation={withTableLayoutV2Styles ? 0 : 1}
        className={classNames(
          classes.tableContainerBase,
          tableContainerCustomClass,
          withTableLayoutV2Styles
            ? classes.tableContainerV2
            : classes.tableContainer
        )}
      >
        <Grid>
          <Grid
            container
            className={classNames(
              !noHeaderPaddings &&
                !withTableLayoutV2Styles &&
                classes.tableHeaderContainer
            )}
            direction="column"
          >
            {(headerActions || withSearchBar) && (
              <Grid
                className={classNames(
                  withTableLayoutV2Styles
                    ? classes.tableSearchBarContainerV2
                    : classes.tableSearchBarContainer,
                  tableSearchBarContainerCustomClass
                )}
                alignItems="center"
                container
                wrap="nowrap"
              >
                {headerActionsBeforeSearchBar}
                {withSearchBar && (
                  <TextField
                    value={searchBarInput}
                    onChange={handleSearchBarInput}
                    InputProps={{
                      classes: {
                        root: withTableLayoutV2Styles
                          ? classes.tableSearchBarRootV2
                          : classes.tableSearchBarRoot,
                        input: withTableLayoutV2Styles
                          ? classes.tableSearchBarInputV2
                          : '',
                        focused: classes.cssFocused,
                        notchedOutline: classes.notchedOutline,
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <MagnifyingGlass />
                        </InputAdornment>
                      ),
                    }}
                    classes={{
                      root: classes.outlinedRoot,
                    }}
                    fullWidth={!withTableLayoutV2Styles}
                    variant="outlined"
                    placeholder={searchPlaceholder}
                  />
                )}
                {headerActions}
              </Grid>
            )}
            {headerAdditionalActions && headerAdditionalActions}
            {tableIsLoading && (
              <LinearProgress
                className={classes.tableLayoutLoader}
                color="primary"
              />
            )}
          </Grid>
          <Table
            aria-label="collapsible supplier table"
            classes={{ root: fixedLayout ? classes.fixedLayout : null }}
            className={classNames(withTableLayoutV2Styles && classes.tableV2)}
          >
            {/* RENDER TABLE HEADER WHEN TABLE IS READY */}
            {!emptyTableData && !tableIsLoading && (
              <TableHead
                className={classNames(
                  !noTableHeadUnderline &&
                    !withTableLayoutV2Styles &&
                    classes.tableHead
                )}
              >
                <TableRow>
                  {withDetailPanel && (
                    <TableCell
                      className={classNames(
                        classes.tableHeaderRowCell,
                        classes.tableHeaderRowCellLeftAction
                      )}
                    />
                  )}
                  {hasCheckBox && (
                    <TableCell
                      className={classNames(
                        classes.tableHeaderRowCell,
                        classes.tableHeaderRowCellLeftAction
                        // !withDetailPanel && classes.tableHeaderCellLeftActionPadding
                      )}
                    >
                      <Checkbox
                        indeterminate={
                          numSelected > 0 && numSelected < itemsOnPage
                        }
                        checked={itemsOnPage > 0 && numSelected === itemsOnPage}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all rows' }}
                      />
                    </TableCell>
                  )}
                  {columns.map(
                    ({
                      sortable,
                      field,
                      columnStyles,
                      title: columnTitle,
                      titleCustomNode,
                    }) =>
                      sortable ? (
                        <TableCell
                          key={field}
                          sortDirection={
                            sortBy === field ? sortDirection : false
                          }
                          className={classNames(
                            classes.tableHeaderRowCell,
                            columnStyles
                          )}
                        >
                          <TableSortLabel
                            active={sortBy === field}
                            direction={sortBy === field ? sortDirection : 'asc'}
                            onClick={handleSortRows(field)}
                          >
                            {columnTitle}
                          </TableSortLabel>
                          {titleCustomNode}
                        </TableCell>
                      ) : (
                        <TableCell
                          key={columnTitle}
                          className={classNames(
                            classes.tableHeaderRowCell,
                            columnStyles
                          )}
                        >
                          {columnTitle}
                          {titleCustomNode}
                        </TableCell>
                      )
                  )}
                  {withActions && (
                    <TableCell
                      align="center"
                      className={classes.tableHeaderRowCell}
                    >
                      {t('common.action')}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            {/* RENDER SKELETON TABLE HEADER */}
            {tableIsLoading && (
              <TableHead>
                <TableRow>
                  {(withDetailPanel || hasCheckBox) && (
                    <TableCell
                      className={classNames(
                        classes.tableHeaderRowCell,
                        classes.tableHeaderRowCellLeftAction
                      )}
                    />
                  )}
                  {columns.map(tableColumn => (
                    <TableCell
                      key={tableColumn.field || tableColumn.title}
                      className={classNames(
                        classes.tableHeaderRowCell,
                        tableColumn.columnStyles
                      )}
                    >
                      <Skeleton />
                    </TableCell>
                  ))}
                  {withActions && (
                    <TableCell
                      align="center"
                      className={classes.tableHeaderRowCell}
                    >
                      <Skeleton />
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            )}
            <ClickAwayListener
              onClickAway={handleClickAway}
              mouseEvent="onMouseDown"
            >
              <TableBody
                className={
                  withTableLayoutV2Styles
                    ? classes.tableBodyV2
                    : classes.tableBody
                }
              >
                {emptyTableData && !tableIsLoading && (
                  <EmptyTableData
                    noResultMessage={emptyTableDataMessage}
                    tableIsLoading={tableIsLoading}
                  />
                )}
                {tableIsLoading && (
                  <TableSkeletons
                    type={skeletonType}
                    rowsPerPage={rowsPerPage}
                    numberOfCells={
                      Number(columns.length ?? 0) +
                      Number(withActions) +
                      Number(withDetailPanel) +
                      Number(hasCheckBox)
                    }
                  />
                )}
                {!tableIsLoading && !emptyTableData && children}
              </TableBody>
            </ClickAwayListener>
          </Table>
        </Grid>
        {tableIsLoading ? (
          <Grid container className={classes.paginationSkeletonContainer}>
            <Skeleton className={classes.rowsPerPageSkeleton} variant="rect" />
            <Skeleton className={classes.pageResultsSkeleton} variant="rect" />
            <Skeleton
              className={classes.paginationActionsSkeleton}
              variant="circle"
            />
            <Skeleton
              className={classes.paginationActionsSkeleton}
              variant="circle"
            />
          </Grid>
        ) : (
          <Grid className={classes.footerRoot}>
            {footerAdditionalActions}
            <TablePagination
              classes={{
                root: classes.tablePaginationRoot,
                toolbar: classes.tablePagination,
                selectRoot: classes.tablePaginationSelectRoot,
                select: classes.tablePaginationSelect,
                actions: classes.tablePaginationActions,
              }}
              backIconButtonProps={{
                'aria-label': t('common.pagination previous'),
                classes: {
                  root: classes.tablePaginationButton,
                },
              }}
              nextIconButtonProps={{
                'aria-label': t('common.pagination next'),
                classes: {
                  root: classes.tablePaginationButton,
                },
              }}
              rowsPerPageOptions={
                customRowsPerPageOptions || ROWS_PER_PAGE_OPTIONS
              }
              labelRowsPerPage={t('common.pagination label')}
              labelDisplayedRows={
                // eslint-disable-next-line max-len
                ({ from, to, count }) =>
                  `${from}-${to} ${t('customers.pagination result')} ${
                    count !== -1 ? count : `more than ${to}`
                  }`
              }
              component="div"
              count={Number.isNaN(totalResults) ? -1 : totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={
                isEditMode
                  ? noop
                  : (event, nextPage) =>
                      handleChangePage(event, nextPage, searchBarInput)
              }
              onRowsPerPageChange={
                isEditMode
                  ? noop
                  : event => handleChangeRowsPerPage(event, searchBarInput)
              }
            />
          </Grid>
        )}
      </TableContainer>
    </>
  );
}

TableLayout.propTypes = {
  children: PropTypes.node,
  withTableLayoutV2Styles: PropTypes.bool,
  title: PropTypes.string,
  subtitleNode: PropTypes.node,
  wrapTitle: PropTypes.string,
  titleDirection: PropTypes.string,
  headerActionsBeforeSearchBar: PropTypes.node,
  columns: PropTypes.array.isRequired,
  handleSearchBar: PropTypes.func,
  handleShowSearchResult: PropTypes.func,
  withSearchBar: PropTypes.bool,
  withDetailPanel: PropTypes.bool,
  withActions: PropTypes.bool,
  page: PropTypes.number,
  totalResults: PropTypes.number,
  rowsPerPage: PropTypes.number,
  handleChangePage: PropTypes.func,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  headerActions: PropTypes.node,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.number,
  handleSortRows: PropTypes.func,
  handleClickAway: PropTypes.func,
  isEditMode: PropTypes.bool,
  hasCheckBox: PropTypes.bool,
  numSelected: PropTypes.number,
  onSelectAllClick: PropTypes.func,
  itemsOnPage: PropTypes.number,
  emptyTableData: PropTypes.bool,
  emptyTableDataMessage: PropTypes.string,
  headerAdditionalActions: PropTypes.node,
  backButtonAction: PropTypes.func,
  tableIsLoading: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  skeletonType: PropTypes.string.isRequired,
  urlSearchParameter: PropTypes.string,
  fixedLayout: PropTypes.bool,
  footerAdditionalActions: PropTypes.node,
  noHeaderPaddings: PropTypes.bool,
  noTableHeadUnderline: PropTypes.bool,
  tableContainerCustomClass: PropTypes.string,
  tableSearchBarContainerCustomClass: PropTypes.string,
  customRowsPerPageOptions: PropTypes.array,
};

TableLayout.defaultProps = {
  withTableLayoutV2Styles: false,
  title: '',
  subtitleNode: null,
  wrapTitle: null,
  titleDirection: null,
  headerActionsBeforeSearchBar: null,
  children: null,
  headerActions: null,
  headerAdditionalActions: null,
  footerAdditionalActions: null,
  sortBy: '',
  page: 0,
  rowsPerPage: 10,
  totalResults: 1,
  withSearchBar: false,
  withDetailPanel: false,
  withActions: false,
  sortOrder: -1,
  handleSortRows: noop,
  handleClickAway: noop,
  handleChangePage: noop,
  isEditMode: false,
  hasCheckBox: false,
  numSelected: 0,
  onSelectAllClick: noop,
  itemsOnPage: 0,
  emptyTableData: false,
  emptyTableDataMessage: '',
  handleSearchBar: noop,
  handleShowSearchResult: noop,
  tableIsLoading: false,
  fixedLayout: false,
  noHeaderPaddings: false,
  noTableHeadUnderline: false,
  backButtonAction: null,
  searchPlaceholder: '',
  urlSearchParameter: '',
  tableContainerCustomClass: '',
  tableSearchBarContainerCustomClass: '',
  customRowsPerPageOptions: null,
};
