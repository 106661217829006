import React, { useEffect, memo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';

import { GET_PRODUCTS_BY_ID } from 'graphql/query/products';
import { useLazyQuery } from '@apollo/client';
import { activeAccountIdVar } from 'graphql/apolloReactiveVariables';

import { makeStyles } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { NOTIFICATION_STATUS } from 'helpers/constants';
// import ProductsLoader from 'components/AIOrderReview/ProductsList/ProductsLoader';
import ProductRow from 'components/AIOrderReview/ProductsList/ProductRow';
import {
  getCartRestrictionV2,
  checkCartV2Validity,
  NEW_PRODUCT_ROW_PREFIX,
} from 'helpers/cartValidationHelpers';
import { parseDataValidationErrors } from 'helpers/getErrorMessage';

const useStyles = makeStyles(({ spacing, palette, typography }) => ({
  orderDetailsAllProductsContainer: {
    marginTop: spacing(5),
    height: '100%',
  },
  orderDetailsAllProductsTableContainer: {
    height: '100%',
    borderRadius: 8,
    overflow: 'hidden',
    border: palette.border.grey,
    '& div[data-attr="product-table"]:nth-child(odd)': {
      background: palette.background.paper,
    },
    '& div[data-attr="product-table"]:nth-child(even)': {
      background: palette.background.mutedLight,
    },
  },
  orderDetailProductNumber: {
    width: 24,
    marginRight: spacing(3),
  },
  orderDetProductsTableHeader: {
    minHeight: 40,
    ...typography.body2,
    fontSize: typography.pxToRem(14),
    fontWeight: typography.fontWeightRegular,
    color: palette.text.secondary,
    padding: spacing(1.5),
  },
  aiOrdersQuantityCell: {
    minWidth: 100,
  },
}));

function OrderDetailsAllProductsList({
  orderId,
  orderProducts,
  productsState,
  hasMultipleProductTypes,
  setProductsState,
  isEditingDisabled,
  isOrderEditableByStatus,
  setNotification,
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const vendorId = activeAccountIdVar();

  const [getAllProductsById] = useLazyQuery(GET_PRODUCTS_BY_ID, {
    onError: error => {
      setNotification({
        variables: {
          timeout: 4000,
          message:
            parseDataValidationErrors(error) ||
            t('aiOrders.errors.can not get products'),
          type: NOTIFICATION_STATUS.ALERT,
          isOpen: true,
        },
      });
    },
    onCompleted: data => {
      if (data?.productsById?.products) {
        setProductsState(prevState => ({
          ...prevState,
          products: orderProducts,
          productsData: data.productsById.products.reduce(
            (init, product) => ({
              ...init,
              [product._id]: product,
            }),
            {}
          ),
          productErrors: checkCartV2Validity(orderProducts),
        }));
      }
    },
  });

  useEffect(() => {
    if (orderProducts.length) {
      const allProductIds = orderProducts.reduce((init, product) => {
        const uniqueProductIds = new Set();
        uniqueProductIds
          .add(product?.selectedProduct?._id)
          .add(product.matchingResult?.product?._id);
        return [...init, ...Array.from(uniqueProductIds).filter(Boolean)];
      }, []);

      getAllProductsById({
        variables: {
          productIds: allProductIds,
          pageSize: orderProducts.length,
          vendorId,
        },
      });
    }
    // DEMO: now can be used for 100 items otherwise need to add dependency to get new products after pagination result
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const handlePackagingChange = (productRowId, variantId) => {
    setProductsState(prevState => {
      let updatedProduct;
      const products = prevState.products.map(productByRow => {
        if (productRowId === productByRow._id) {
          updatedProduct = {
            ...productByRow,
            selectedProduct: {
              ...productByRow.selectedProduct,
              variantId,
            },
          };
          return updatedProduct;
        } else {
          return productByRow;
        }
      });

      return {
        ...prevState,
        products,
        updatedProducts: {
          ...prevState.updatedProducts,
          [productRowId]: updatedProduct,
        },
        productErrors: checkCartV2Validity(products),
      };
    });
  };

  const handleChangeQty = (productRowId, qty) => {
    const parsedQty = parseFloat(qty) || 0;

    setProductsState(prevState => {
      let updatedProduct;
      const products = prevState.products.map(productByRow => {
        if (productRowId === productByRow._id) {
          updatedProduct = {
            ...productByRow,
            selectedProduct: {
              ...productByRow.selectedProduct,
              qty: parsedQty,
            },
          };
          return updatedProduct;
        } else {
          return productByRow;
        }
      });

      return {
        ...prevState,
        products,
        productErrors: checkCartV2Validity(products),
        updatedProducts: {
          ...prevState.updatedProducts,
          [productRowId]: updatedProduct,
        },
      };
    });
  };

  const handleSelectProductFromSearch = ({
    productRowId,
    newProduct,
    newProductData,
  }) => {
    setProductsState(prevState => {
      let updatedProduct;
      const products = prevState.products.map(productByRow => {
        if (productRowId === productByRow._id) {
          updatedProduct = {
            ...productByRow,
            selectedProduct: newProduct,
          };
          return updatedProduct;
        } else {
          return productByRow;
        }
      });

      return {
        ...prevState,
        products,
        productsData: {
          ...prevState.productsData,
          [newProduct._id]: newProductData,
        },
        updatedProducts: {
          ...prevState.updatedProducts,
          [productRowId]: updatedProduct,
        },
        productErrors: checkCartV2Validity(products),
      };
    });
  };

  const handleDeleteProduct = productRowId => () => {
    setProductsState(prevState => {
      const productToDelete = prevState.products.find(
        p => p._id === productRowId
      );

      if (!productToDelete) {
        return prevState;
      } else {
        const updatedProducts = { ...prevState.updatedProducts };

        // If it is newly created product, just remove it from the list otherwise keep it with qty 0
        if (productRowId.includes(NEW_PRODUCT_ROW_PREFIX)) {
          delete updatedProducts[productRowId];
        } else {
          updatedProducts[productRowId] = {
            ...productToDelete,
            selectedProduct: {
              ...(productToDelete.selectedProduct || {}),
              qty: 0,
            },
          };
        }

        const products = prevState.products.map(product => {
          if (product._id === productRowId) {
            return {
              ...product,
              selectedProduct: {
                ...product.selectedProduct,
                qty: 0,
              },
            };
          } else {
            return product;
          }
        });

        return {
          ...prevState,
          products,
          updatedProducts,
        };
      }
    });
  };

  return (
    <Grid
      className={classes.orderDetailsAllProductsContainer}
      container
      item
      xs={12}
      direction="column"
    >
      <Grid
        className={classes.orderDetailsAllProductsTableContainer}
        container
        direction="column"
      >
        <Grid
          data-attr="product-table"
          className={classes.orderDetProductsTableHeader}
          container
          item
          xs={12}
          direction="row"
        >
          <Grid item className={classes.orderDetailProductNumber} />
          <Grid item xs={3}>
            <Typography variant="inherit" color="inherit">
              ID
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="inherit" color="inherit">
              {t('aiOrders.product name')}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography variant="inherit" color="inherit">
              {t('aiOrders.packaging')}
            </Typography>
          </Grid>
          <Grid className={classes.aiOrdersQuantityCell} item xs={2}>
            <Typography variant="inherit" color="inherit">
              {t('aiOrders.quantity')}
            </Typography>
          </Grid>
        </Grid>
        {productsState?.products?.map((productByRow, pIndex) => {
          const {
            _id: rowId,
            selectedProduct,
            parsedProduct = {},
          } = productByRow;

          // const matchingResultProduct = matchingResult?.product;

          const currentProductData =
            productsState.productsData[selectedProduct?._id] || selectedProduct;

          const cartRestrictionsPerPackaging = getCartRestrictionV2(
            currentProductData?.variants
          );
          return (
            <ProductRow
              key={rowId}
              productRowId={rowId}
              rowIndex={pIndex}
              product={selectedProduct}
              productData={currentProductData}
              rawLLMProduct={parsedProduct}
              error={productsState.productErrors[rowId]}
              cartRestrictionsPerPackaging={cartRestrictionsPerPackaging}
              hasMultipleProductTypes={hasMultipleProductTypes}
              isEditingDisabled={isEditingDisabled}
              isOrderEditableByStatus={isOrderEditableByStatus}
              handlePackagingChange={handlePackagingChange}
              handleChangeQty={handleChangeQty}
              handleSelectProductFromSearch={handleSelectProductFromSearch}
              handleDeleteProduct={handleDeleteProduct}
            />
          );
        })}
        {/* {isAllProductsLoading ? (
          <ProductsLoader parentClasses={classes} />
        ) : (
          productsState?.products?.map((product, pIndex) => (
            <ProductRow
              // TODO: support attributes as a key as well
              // eslint-disable-next-line react/no-array-index-key
              key={`${product._id}-${product.variantId}-${pIndex}`}
              rowIndex={pIndex}
              product={product}
              productData={productsState.productsData[product._id] || product}
              handlePackagingChange={handlePackagingChange}
              handleChangeQty={handleChangeQty}
              handleSelectProductFromSearch={handleSelectProductFromSearch}
              handleDeleteProduct={handleDeleteProduct}
            />
          ))
        )} */}
      </Grid>
    </Grid>
  );
}

OrderDetailsAllProductsList.propTypes = {
  orderId: PropTypes.string.isRequired,
  orderProducts: PropTypes.array,
  productsState: PropTypes.object.isRequired,
  hasMultipleProductTypes: PropTypes.bool.isRequired,
  isEditingDisabled: PropTypes.bool.isRequired,
  isOrderEditableByStatus: PropTypes.bool.isRequired,
  setProductsState: PropTypes.func.isRequired,
  setNotification: PropTypes.func.isRequired,
};

OrderDetailsAllProductsList.defaultProps = {
  orderProducts: [],
};

export default memo(
  OrderDetailsAllProductsList,
  (prevProps, nextProps) =>
    prevProps.orderId === nextProps.orderId &&
    isEqual(prevProps.orderProducts, nextProps.orderProducts) &&
    isEqual(prevProps.productsState, nextProps.productsState) &&
    prevProps.isEditingDisabled === nextProps.isEditingDisabled &&
    prevProps.isOrderEditableByStatus === nextProps.isOrderEditableByStatus
);
